import React, { useState } from 'react';
import Layout from './components/Layout/Layout';
import './App.css';
import 'fontsource-roboto';

function App() {
	const [scroll, setScroll] = useState(true);

	const noScrollHandler = () => {
		setScroll(false);
	};
	const scrollHandler = () => {
		setScroll(true);
	};

	let classes;
	if (!scroll) {
		classes = 'App NoScroll';
	} else {
		classes = 'App';
	}

	return (
		<div className={classes}>
			<Layout noScroll={noScrollHandler} scroll={scrollHandler} />
		</div>
	);
}

export default App;
