import classes from './Card.module.css';
import React from 'react';

const Card = props => {
	const gridPosition = {
		gridColumn: props.column,
		gridRow: props.row
	};

	return (
		<figure
			style={gridPosition}
			className={classes.Card}
			onClick={props.onClick}
		>
			<div>
				<img
					src={props.img}
					alt={props.alt}
					width={props.width}
					height={props.height}
				/>
			</div>
			<div className={classes.Text}>
				<h2>{props.heading}</h2>
				<p>{props.content}</p>
				<p className={classes.Mehr}>Mehr erfahren...</p>
			</div>
		</figure>
	);
};

export default Card;
