import classes from './Modal.module.css';
import React from 'react';

const Modal = props => {
	let modalClasses = [];

	if (props.show) {
		modalClasses = [classes.Modal, classes.Show];
	} else {
		modalClasses = [classes.Modal];
	}
	return (
		<div className={modalClasses.join(' ')} onClick={props.toggle}>
			{props.children}
		</div>
	);
};

export default Modal;
