import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from '../Header/Header';
import ContentBlock from '../ContentBlock/ContentBlock';
import Footer from '../Footer/Footer';
import Contact from '../Contact/Contact';
import classes from './Layout.module.css';
import Impressum from '../Impressum/Impressum';
import Privacy from '../Privacy/Privacy';
import About from '../About/About';
import Navigation from '../Navigation/Navigation';
import Heating from '../Heating/Heating';
import Sanitary from '../Sanitary/Sanitary';
import Clima from '../Clima/Clima';
import Modal from '../Modal/Modal';
import Emergency from '../Emergency/Emergency';

const Layout = props => {
	const [scrollPosition, setSrollPosition] = useState(0);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [showMenu, setShowMenu] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState(null);

	const [mobile, setMobile] = useState(false);

	useEffect(() => {
		if (windowWidth < 600 && !mobile) {
			setMobile(true);
		} else if (windowWidth > 600 && mobile) {
			setMobile(false);
			hideMenuHandler();
		}
	}, [mobile, setMobile, windowWidth]);

	let classe = classes.Layout;
	const showMenuHandler = () => {
		setShowMenu(prev => !prev);
	};
	const hideMenuHandler = () => {
		setShowMenu(false);
	};

	const handleScroll = () => {
		const position = window.pageYOffset;
		setSrollPosition(position);
	};

	const toggleShowModal = () => {
		setShowModal(prev => !prev);
	};

	const setModalContentHandler = content => {
		setModalContent(content);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });
		window.onresize = updateWindowSize;
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const updateWindowSize = () => {
		setWindowWidth(window.innerWidth);
	};
	if (showMenu === true) {
		classe = classes.Layout + ' ' + classes.Show;
		props.noScroll();
	} else if (showMenu === false) {
		classe = classes.Layout + ' ' + classes.Hide;
		props.scroll();
	} else {
		classe = classes.Layout;
	}
	return (
		<BrowserRouter>
			<div className={classe}>
				<div className={classes.MobileBackground}></div>
				<Header
					scroll={scrollPosition}
					window={windowWidth}
					showMenu={showMenuHandler}
				/>
				<div className={classes.HeaderDummy}></div>
				<Navigation
					classe={mobile ? 'mobile' : 'notMobile'}
					onClick={hideMenuHandler}
				/>
				<Modal show={showModal} toggle={toggleShowModal}>
					{modalContent}
				</Modal>
				<Switch>
					<Route path='/contact'>
						<Contact />
					</Route>
					<Route path='/about'>
						<About />
					</Route>
					<Route path='/emergency'>
						<Emergency />
					</Route>
					<Route path='/heating'>
						<Heating
							setModalContent={setModalContentHandler}
							toggleModal={toggleShowModal}
						/>
					</Route>
					<Route path='/sanitary'>
						<Sanitary
							setModalContent={setModalContentHandler}
							toggleModal={toggleShowModal}
						/>
					</Route>
					<Route path='/clima'>
						<Clima
							setModalContent={setModalContentHandler}
							toggleModal={toggleShowModal}
						/>
					</Route>
					<Route path='/impressum'>
						<Impressum />
					</Route>
					<Route path='/privacy'>
						<Privacy />
					</Route>
					<Route path='/'>
						<ContentBlock window={windowWidth} />
					</Route>
				</Switch>
				<div className={classes.FooterDummy}></div>
				<Footer />
			</div>
		</BrowserRouter>
	);
};

export default Layout;
