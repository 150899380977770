import classes from './Heating.module.css';
import React, { useEffect } from 'react';
import ControlledAccordions from '../MaterialUI/ControlledAccordion/ControlledAccordion';

import BHKW_Schema from '../../assets/pictures/Bhkw_schema.png';
import BZ_Image from '../../assets/pictures/Brennstoffzelle.Panasonic.Viessmann_frei_ULD.png';
import WP_Image from '../../assets/pictures/Heatpump2.svg';
import HeatingImg from '../../assets/pictures/heat-pumps-393059_640.jpg';
import GasHeatingImg from '../../assets/pictures/Heizung_clean.jpg';
import GasHeatingImg2 from '../../assets/pictures/Gasheizung_Wolf_320.jpg';
import GasHeatingImg2HD from '../../assets/pictures/Gasheizung_Wolf_1280.jpg';

import CornerHeating from '../../assets/pictures/Heizung_Ecke_320.jpg';
import CornerHeatingHD from '../../assets/pictures/Heizung_Ecke_1280.jpg';
import RoofHeating from '../../assets/pictures/Heizung_Dach_320.jpg';
import RoofHeatingHD from '../../assets/pictures/Heizung_Dach_1280.jpg';

const Heating = props => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const zoomImageHandler = img => {
		props.setModalContent(<img src={img} alt='zoomedImage' />);
		props.toggleModal();
		window.scrollTo(0, 0);
	};

	const heatingHeading = [
		'Gasheizung',
		'Brennstoffzellenheizung',
		'Blockheizkraftwerk',
		'Wärmepumpe'
	];
	const heatingSecondHeading = ['', '', '', ''];
	const heatingContent = [
		<figure className={classes.AccContentDiv}>
			<img
				src={GasHeatingImg}
				alt='Gasheizung'
				onClick={() => {
					zoomImageHandler(GasHeatingImg);
				}}
			/>

			<h3>Gas als Wärmelieferant</h3>

			<p>
				Erdgas ist ein schadstoffarm zu verbrennender Energieträger,
				welcher seit vielen Jahrzehnten für Heizungsanlagen genutzt
				wird. Circa 50 Prozent aller Wohngebäude werden in Deutschland
				mit Gas beheizt. Der Brennstoff wird über Rohrleitungen seitens
				des örtlichen Versorgers direkt an den Abnehmer weitergeleitet.
				Wenn keine Versorgung mit Erdgas möglich ist, kann alternativ
				Flüssiggas genutzt werden, welches in einem Flüssiggastank
				gelagert wird. Erdgas ist ein fossiler Brennstoff, welcher zum
				überwiegenden Teil aus Methan besteht und im Prinzip geruchsfrei
				ist. Aus Sicherheitsgründen wird dem Erdgas ein Warn-Duftstoff
				beigemischt, damit im Falle eines Gasaustritts die Bewohner
				diesen riechen können.
			</p>
		</figure>,
		<figure className={classes.AccContentDiv}>
			<div className={classes.Attribution}>
				<img
					src={BZ_Image}
					alt='Brennstoffzellenheizung Viessmann/Panasonic'
					style={{ cursor: 'default' }}
				/>
				<a href='https://commons.wikimedia.org/wiki/File:Brennstoffzelle.Panasonic.Viessmann.jpg'>
					Bautsch
				</a>
				CC0, via Wikimedia Commons
			</div>

			<h3>Energieumwandlung statt Verbrennung</h3>

			<p>
				Die Brennstoffzellenheizung ist eine der modernsten Varianten
				aller derzeit zur Verfügung stehenden Heizungsarten. Sie
				zeichnet sich durch ihre hervorragende Energieeffizienz aus und
				ist zudem sehr leise und schadstoffarm.
			</p>

			<p>
				Bei der Reaktion von Sauerstoff und Wasserstoff entsteht
				Energie. Die moderne Technik der Brennstoffzellenheizung nutzt
				diesen einfachen chemischen Prozess, welcher vollkommen ohne die
				Verbrennung von Öl oder Gas auskommt, zur gleichzeitigen Strom-
				und Wärmeerzeugung und macht diesen auch im Privathaushalt
				einsetzbar. Die Brennstoffzellenheizung ist die modernste und
				effizienteste aller Heizarten und zeichnet sich durch ihre
				hervorragende Energieeffizienz aus, ist leise und schadstoffarm,
				denn statt eines hohen Grades an umweltschädigenden Emissionen
				entsteht bei Brennstoffzellenheizungen hauptsächlich eines:
				Wasserdampf. Die Brennstoffzellenheizung besteht aus
				Brennstoffzellen für die Erzeugung des Wärme- und
				Stromgrundbedarfs und einem Gas-Brennwertkessel, welcher die
				Deckung des darüber hinausgehenden Wärmebedarfs sicherstellt.
				Brennstoffzellenheizungen sind sowohl für den Einsatz in Ein-
				und Zweifamilienhäusern als auch im Kleingewerbe geeignet. Der
				Anschluss an ein bestehendes Heizsystem mit entsprechenden
				Warmwasserleitungen sowohl für Wand- als auch Fußbodenheizungen
				ist unkompliziert.
			</p>
		</figure>,
		<figure className={classes.AccContentDiv}>
			<div className={classes.Attribution}>
				<img
					src={BHKW_Schema}
					alt='BHKW-Schema'
					onClick={() => {
						zoomImageHandler(BHKW_Schema);
					}}
				/>
				<a href='https://commons.wikimedia.org/wiki/File:Bhkw_schema.png'>
					Peter Lehmacher
				</a>
				<a href='http://creativecommons.org/licenses/by-sa/3.0/'>
					CC BY-SA 3.0
				</a>
				via Wikimedia Commons
			</div>
			<h3>Blockheizkraftwerke</h3>
			<p>
				Kurz BHKW, erzeugen gleichzeitig Strom und Wärme und gehören zu
				den KWK-Anlagen (Kraft-/Wärme-Kopplung).
			</p>

			<p>
				Bei BHKW geht es um die effiziente Nutzung von Energie. In der
				Vergangenheit wurden das sogenannte Mini-BHKW, das Micro-BHKW
				und das Nano-BHKW entwickelt. Diese sind kleiner und kompakter
				und dafür konzipiert kleinere Gebäude, wie beispielsweise
				Einfamilienhäuser, zu versorgen. BHKW lassen sich mit der
				bereits vorhandenen, herkömmlichen Haustechnik kombinieren.
				Daher bieten sie sich sowohl im Neubau als auch bei
				Gebäudemodernisierungen an. Der Einsatz eines BHKW ist immer
				dann besonders effizient, wenn der gleichzeitige Bedarf von
				Wärme und Strom bestehen. Außerdem sind hohe
				Vollbenutzungsstunden sinnvoll.
			</p>
		</figure>,
		<figure className={classes.AccContentDiv}>
			<img
				src={WP_Image}
				alt='Schema Wärmepumpe'
				onClick={() => {
					zoomImageHandler(WP_Image);
				}}
			/>
			<h3>Kostenlose Energie aus der Umgebung</h3>

			<p>
				Nutzen Sie sinnvoll und effizient die Energie aus Luft,
				Grundwasser und Erdreich
			</p>

			<p>
				Moderne Wärmepumpen sind wahre Allround-Talente. Mit der
				neuesten Generation von Wärmepumpen können Sie Warmwasser
				erzeugen, Ihr Zuhause heizen, kühlen und sogar lüften. Dabei
				schonen Sie gleichzeitig die Umwelt und senken Ihre Heizkosten.
				Klingt gut? Ist es auch!
			</p>
			<p>
				Bei der Wärmepumpe wird die Wärme von einem Medium unter Druck,
				an die Verbrauchsstellen(1) abgegeben. Das Medium verdampft
				hinter einer Drossel(2) zu niedrigerem Druck. Wodurch es stark
				erkaltet und dadurch fähig wird, auch aus geringer
				Umgebungstemperatur, Wärmeenergie aufzunehmen(3). Durch zufügen
				weiterer Energie durch Kompression(4) wird die Temperatur des
				Mediums weiter erhöht und der Kreislauf ist geschlossen.
			</p>
		</figure>
	];

	return (
		<figure className={classes.Heating}>
			<h1>Heizung</h1>
			<div className={classes.ImgDiv}>
				<img
					src={HeatingImg}
					alt='Heizungsanlage - Familie'
					onClick={() => {
						zoomImageHandler(HeatingImg);
					}}
				/>
				<img
					style={{
						float: 'right',
						marginRight: '0',
						marginLeft: '10px',
						width: '20%'
					}}
					src={GasHeatingImg2}
					alt='Heizungsanlage - Familie'
					onClick={() => {
						zoomImageHandler(GasHeatingImg2HD);
					}}
				/>

				<p>
					Ob neue Heizungsanlage, Sanierung, Reparatur, Wartung oder
					Beratung. Wir sind für Sie da. Eine umfassende, individuelle
					Beratung ist für uns selbstverständlich. Unsere Arbeiten
					führen wir nach den neusten Umweltrichtlinien und
					Vorschriften aus.
				</p>
				<p>
					Heizwert oder Brennwert, Öl, Gas oder vielleicht auch
					Pellets? Blockheizkraftwerk, Wärmepumpe, Brennstoffzelle?
					Ihr Ziel ist unsere Herausforderung.
				</p>
				<p>
					Wärmeservice: Benötigen Sie eine neue Heizungsanlage für Ihr
					Ein- oder Mehrfamilienhaus, verfügen aber nicht über die
					notwendige Liquidität? Auch dabei können wir Ihnen helfen.
					Zusammen mit der Wuppertaler Stadtwerke AG lösen wir auch
					dieses Problem. Wir beraten Sie gerne!
				</p>
			</div>
			<ControlledAccordions
				heading={heatingHeading}
				secondHeading={heatingSecondHeading}
				content={heatingContent}
			/>
			<div className={classes.Gallery}>
				<img
					src={CornerHeating}
					alt='Heizungsanlage - Familie'
					onClick={() => {
						zoomImageHandler(CornerHeatingHD);
					}}
				/>
				<img
					src={RoofHeating}
					alt='Heizungsanlage - Familie'
					onClick={() => {
						zoomImageHandler(RoofHeatingHD);
					}}
				/>
			</div>
		</figure>
	);
};

export default Heating;
