import classes from './Clima.module.css';
import React, { useEffect } from 'react';

import PassivHouse from '../../assets/pictures/Passivhaus_schema_1.svg';

const Clima = props => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const zoomImageHandler = img => {
		props.setModalContent(<img src={img} alt='zoomedImage' />);
		props.toggleModal();
		window.scrollTo(0, 0);
	};

	return (
		<div className={classes.Clima}>
			<h1>Klimatechnik</h1>
			<div className={classes.ImageDiv}>
				<img
					src={PassivHouse}
					alt='Passiv-Haus-Schema'
					onClick={() => {
						zoomImageHandler(PassivHouse);
					}}
				/>
				<a href='https://commons.wikimedia.org/wiki/File:Passivhaus_schema_1.svg'>
					Passivhaus_Schema.png: Thomas Langerderivative work: Michka
					B
				</a>
				<a href='http://creativecommons.org/licenses/by-sa/3.0/'>
					CC BY-SA 3.0
				</a>
				<p>via Wikimedia Commons</p>
			</div>
			<p>
				Auch in Fragen zur Klimatechnik und deren Möglichkeit stehen wir
				Ihnen gern mit Rat und Tat zur Seite. Wir arbeiten mit vielen
				namhaften Marken und Herstellern zusammen und greifen auf diese
				Weise auf ein großes Angebot zurück, um für Ihr Objekt nicht nur
				eine, sondern gleiche mehrere wirkungsvolle Lösungen anbieten zu
				können.
			</p>
			<p>
				Besonders im gewerblichen Bereich kann eine gute und
				funktionierende Belüftung zwingende Voraussetzung sein. Immer
				dann, wenn mit geruchsintensiven Stoffen oder Chemikalien
				gearbeitet wird, müssen unangenehme oder giftige Dämpfe schnell
				abgesaugt und ausgetauscht werden. Hier sind wir Ihr
				Ansprechpartner, denn wir konzipieren anspruchsvolle
				Lüftungsanlagen sowohl für den privaten als auch den
				gewerblichen Sektor.
			</p>
		</div>
	);
};

export default Clima;
