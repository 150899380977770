import classes from './Navigation.module.css';
import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

const Navigation = props => {
	const [showDropDown, setShowDropDown] = useState(false);
	let classe = [classes.Navigation];
	let dDClasses = [classes.DropDown];

	if (showDropDown) {
		dDClasses.push(classes.ShowDropDown);
	} else {
		dDClasses.filter(item => item !== classes.ShowDropDown);
	}

	if (props.classe === 'mobile') {
		classe.push(classes.Mobile);
	} else {
		classe.filter(item => item !== classes.Mobile);
	}

	const openDropDownHandler = () => {
		setShowDropDown(prev => !prev);
	};

	const closeDropDownHandler = () => {
		if (showDropDown) {
			setShowDropDown(false);
		}
		props.onClick();
	};

	return (
		<nav onClick={closeDropDownHandler} className={classe.join(' ')}>
			<NavLink to='/' exact activeClassName={classes.Active}>
				Home
			</NavLink>

			<NavLink to='/about' exact activeClassName={classes.Active}>
				Über&nbsp;Uns
			</NavLink>
			<NavLink to='/contact' exact activeClassName={classes.Active}>
				Kontakt
			</NavLink>
			<div className={classes.DropDownWrapper}>
				<button onClick={openDropDownHandler}>Mehr...</button>
				<div
					onClick={closeDropDownHandler}
					className={dDClasses.join(' ')}
				>
					<Link to='/sanitary'>Sanitär</Link>
					<Link to='/heating'>Heizung</Link>
					<Link to='/clima'>Klima</Link>
				</div>
			</div>
			<NavLink to='/emergency' activeClassName={classes.Active}>
				Notdienst (24h)
			</NavLink>
		</nav>
	);
};

export default Navigation;
