import classes from './About.module.css';
import React from 'react';
import MonteurImg from '../../assets/pictures/monteur_armatur_640.jpg';

const About = () => {
	return (
		<div className={classes.About}>
			<h1>Über uns</h1>
			<div className={classes.Container}>
				<img
					src={MonteurImg}
					alt='Monteur für Mischbatterie Waschbecken'
				/>
				<p>
					Beim AVM - Meisterbetrieb stehen Sie als Kunde im
					Mittelpunkt. <br />
					Wir stehen an Ihrer Seite rund um alle Aspekte in den
					Bereichen Sanitär, Heizung und Klimatechnik. <br />
					Mit stets aktuellem KnowHow begleiten wir Sie von einer
					ersten, unverbindlichen Beratung, bis zur Vollendung Ihres
					Projekts, stets Hand in Hand.
					<br />
					Dabei finden wir auch für schwierigere Aufgaben stets eine
					passende Lösung.
				</p>
				<p>
					AVM - Meisterbetrieb, der Handwerks- und Ausbildungsbetrieb
					Ihres Vertrauens.
				</p>
			</div>
		</div>
	);
};

export default About;
