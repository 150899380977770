import classes from "./Header.module.css";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import Logo from "../../assets/pictures/header/Logo_final_plusText_shadow_30_6_07_300px.png";
import WideLogo from "../../assets/pictures/header/Logo_final_plusText_shadow_30_6_07_300px.png";

const Header = (props) => {
  const [mobile, setMobile] = useState(false);
  const [logo, setLogo] = useState({
    img: Logo,
    width: "283",
    aspectRatio: 1.145,
  });

  const { window } = props;

  useEffect(() => {
    if (window < 600 && !mobile) {
      setMobile(true);
      setLogo({ img: Logo, width: "283", aspectRatio: 1.415 });
    } else if (window > 600 && mobile) {
      setMobile(false);
      setLogo({ img: WideLogo, width: "250", aspectRatio: 2.135 });
    } else if (window > 600) {
      setLogo({ img: WideLogo, width: "250", aspectRatio: 2.135 });
    }
  }, [mobile, setMobile, window]);

  const burger = (
    <div className={classes.BurgerButton} onClick={props.showMenu}>
      <div className={classes.Burger}>
        <div></div>
      </div>
    </div>
  );

  return (
    <header className={classes.Header}>
      <div className={classes.Phone}>
        <h1>
          <FontAwesomeIcon icon={faMobileAlt} />
          <a href="tel:+4917682014579">+49 (0)176 82014579</a>
        </h1>
        <h1>
          <FontAwesomeIcon icon={faPhoneAlt} />
          <a href="tel:+4920229541651">+49 (0)202 29541651</a>
        </h1>
      </div>
      <a href="/" className={classes.Logo}>
        <img
          style={{ aspectRatio: logo.aspectRatio }}
          src={logo.img}
          alt="Logo: AVM - Meisterbetrieb"
          width={logo.width}
          height={logo.height}
        />
      </a>
      {mobile ? burger : null}
    </header>
  );
};

export default Header;
