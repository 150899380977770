import classes from './Sanitary.module.css';
import React, { useEffect } from 'react';

import Bad_1_Image from '../../assets/pictures/WC_Waschtisch_320.jpg';
import Bad_2_Image from '../../assets/pictures/Bad_Eckwanne_320.jpg';
import Bad_3_Image from '../../assets/pictures/Waschtisch_320.jpg';
import Bad_4_Image from '../../assets/pictures/Dusche_640.jpg';
import Bad_1_ImageHD from '../../assets/pictures/WC_Waschtisch_1280.jpg';
import Bad_2_ImageHD from '../../assets/pictures/Bad_Eckwanne_1280.jpg';
import Bad_3_ImageHD from '../../assets/pictures/Waschtisch_1280.jpg';
import Bad_4_ImageHD from '../../assets/pictures/Dusche_1280.jpg';

const Sanitary = props => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const zoomImageHandler = img => {
		props.setModalContent(<img src={img} alt='zoomedImage' />);
		props.toggleModal();
		window.scrollTo(0, 0);
	};

	return (
		<div className={classes.Sanitary}>
			<h1>Sanitär</h1>

			<figure className={classes.Figure}>
				<img
					src={Bad_1_Image}
					alt='Badezimmer weiß'
					onClick={() => {
						zoomImageHandler(Bad_1_ImageHD);
					}}
				/>
				<p>
					Das Badezimmer ist in jedem Haushalt ein besonderer Ort,
					welchem oft zu wenig Aufmerksamkeit geschenkt wird. In Ihrem
					Bad möchten Sie entspannen, und sich wohl fühlen. Daher
					sollte dieses wohlgeplant sein.
				</p>
			</figure>

			<figure className={classes.Figure}>
				<img
					src={Bad_2_Image}
					alt='Badezimmer weiß'
					onClick={() => {
						zoomImageHandler(Bad_2_ImageHD);
					}}
				/>
				<p>
					Wir möchten Ihnen gern dabei helfen Ihr Bad langfristig und
					nachhaltig zu planen. Dabei beleuchten wir zusammen mit
					Ihnen alle wichtigen Themen, wie Ressourcenschonung,
					Hygiene, Barrierefreiheit, Nachhaltigkeit und nicht zuletzt
					natürlich Ästethik.
				</p>
			</figure>

			<figure className={classes.Figure}>
				<img
					src={Bad_4_Image}
					alt='Badezimmer weiß'
					onClick={() => {
						zoomImageHandler(Bad_4_ImageHD);
					}}
				/>

				<p>
					Ob neues Bad oder (Teil-)Sanierung, ob Funktionalität auf
					engstem Raum, oder Wohlfühloase, wir sind für Sie da. Gerne
					beraten wir Sie umfassend und individuell.
					<br />
					Ihren Wünschen sind hier keine Grenzen gesetzt. Lassen Sie
					uns gemeinsam Ihren Traum vom Bad realisieren.
				</p>
				<img
					src={Bad_3_Image}
					alt='Badezimmer weiß'
					onClick={() => {
						zoomImageHandler(Bad_3_ImageHD);
					}}
				/>
			</figure>

			<figure className={classes.Figure}>
				<p></p>
			</figure>
		</div>
	);
};

export default Sanitary;
