import classes from './ContentBlock.module.css';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../Card/Card';
import imgWater from '../../assets/pictures/snowflake_640.jpg';
import imgHeat from '../../assets/pictures/fire_640_opt_2.jpg';
import imgBath from '../../assets/pictures/drops-of-water-578897_640.jpg';

const ContentBlock = props => {
	const [mobile, setMobile] = useState(false);

	const [posCardOne, setPosCardOne] = useState({
		col: '2 / 7',
		row: '1 / 3'
	});
	const [posCardTwo, setPosCardTwo] = useState({
		col: '7 / 12',
		row: '1 / 3'
	});
	const [posCardThree, setPosCardThree] = useState({
		col: '12 / 17',
		row: '1 / 3'
	});

	const heating =
		'Wir sorgen für Wärme. Ob hocheffiziente Brennwerttherme, Blockheizkraftwerk, Wärmepumpe oder rein aus erneuerbaren Energien, wir erfüllen Ihre Wünsche.';
	const sanitary =
		'Alles rund ums Badezimmer und Ihrer Wasserversorgung liegt bei uns in besten Händen. Wir möchten Ihre Wünsche erfüllen, zu Ihrer vollsten Zufriedenheit.';
	const climate =
		'Modernste Lüftungssysteme gehören zu unserem Portfolio, sprechen Sie uns an, wir beraten Sie gern.';

	useEffect(() => {
		if (props.window < 600 && !mobile) {
			setMobile(true);
		} else if (props.window > 600 && mobile) {
			setMobile(false);
		}
	}, [props.window, mobile, setMobile]);

	useEffect(() => {
		if (props.window < 700) {
			setPosCardOne({ col: '4 / 15', row: '1 / 3' });
			setPosCardTwo({ col: '4 / 15', row: '3 / 5' });
			setPosCardThree({ col: '4 / 15', row: '5 / 7' });
		} else if (props.window < 1000) {
			setPosCardOne({ col: '2 / 9', row: '1 / 3' });
			setPosCardTwo({ col: '10 / 17', row: '1 / 3' });
			setPosCardThree({ col: '6 / 13', row: '3 / 5' });
		} else if (props.window < 1200) {
			setPosCardOne({ col: '2 / 7', row: '1 / 3' });
			setPosCardTwo({ col: '7 / 12', row: '1 / 3' });
			setPosCardThree({ col: '12 / 17', row: '1 / 3' });
		} else if (props.window < 1400) {
			setPosCardOne({ col: '2 / 6', row: '1 / 3' });
			setPosCardTwo({ col: '7 / 11', row: '1 / 3' });
			setPosCardThree({ col: '12 / 16', row: '1 / 3' });
		} else {
			setPosCardOne({ col: '4 / 7', row: '1 / 3' });
			setPosCardTwo({ col: '8 / 11', row: '1 / 3' });
			setPosCardThree({ col: '12 / 15', row: '1 / 3' });
		}
	}, [props.window]);

	const history = useHistory();

	return (
		<main className={classes.ContentBlock + ' ' + classes.Row}>
			<Card
				img={imgBath}
				alt='Bathroom'
				width='567'
				height='426'
				size='big'
				heading='Sanitär'
				content={sanitary}
				column={posCardOne.col}
				row={posCardOne.row}
				onClick={() => history.push('/sanitary')}
			/>

			<Card
				img={imgHeat}
				alt='Heating'
				width='425'
				height='318'
				size='big'
				heading='Heizung'
				content={heating}
				column={posCardTwo.col}
				row={posCardTwo.row}
				onClick={() => history.push('/heating')}
			/>
			<Card
				img={imgWater}
				alt='klima'
				width='640'
				height='480'
				size='big'
				heading='Klima'
				content={climate}
				column={posCardThree.col}
				row={posCardThree.row}
				onClick={() => history.push('/clima')}
			/>
		</main>
	);
};

export default ContentBlock;
