import React from 'react';
import {
	makeStyles,
	createMuiTheme,
	ThemeProvider
} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',

		backgroundColor: 'transparent'
	},
	heading: {
		fontSize: theme.typography.pxToRem(20),
		flexBasis: '33.33%',
		flexShrink: 0,
		fontWeight: 900,
		color: 'silver'
		//textShadow: '1px 1px 1px black'
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	}
}));

const theme = createMuiTheme({
	overrides: {},
	palette: {
		type: 'dark'
	}
});

export default function ControlledAccordions(props) {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState('');

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<div className={classes.root}>
			<ThemeProvider theme={theme}>
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography className={classes.heading}>
							{props.heading[0]}
						</Typography>
						<Typography className={classes.secondaryHeading}>
							{props.secondHeading[0]}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>{props.content[0]}</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === 'panel2'}
					onChange={handleChange('panel2')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel2bh-content'
						id='panel2bh-header'
					>
						<Typography className={classes.heading}>
							{props.heading[1]}
						</Typography>
						<Typography className={classes.secondaryHeading}>
							{props.secondHeading[1]}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>{props.content[1]}</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === 'panel3'}
					onChange={handleChange('panel3')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel3bh-content'
						id='panel3bh-header'
					>
						<Typography className={classes.heading}>
							{props.heading[2]}
						</Typography>
						<Typography className={classes.secondaryHeading}>
							{props.secondHeading[2]}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<div>{props.content[2]}</div>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === 'panel4'}
					onChange={handleChange('panel4')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel4bh-content'
						id='panel4bh-header'
					>
						<Typography className={classes.heading}>
							{props.heading[3]}
						</Typography>
						<Typography className={classes.secondaryHeading}>
							{props.secondHeading[3]}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>{props.content[3]}</AccordionDetails>
				</Accordion>
			</ThemeProvider>
		</div>
	);
}
