import classes from './Emergency.module.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';

const Emergency = () => {
	return (
		<div id='emergency' className={classes.Emergency}>
			<h2>Notdienst (24h)</h2>
			<div>
				<p>
					<span>Rufen Sie uns an:</span>
					<br />
					Im Notfall erreichen Sie uns 24h am Tag
					<br />
					unter dieser Nummer:
					<br />
					<br />
				</p>
				<div className={classes.Phone}>
					<div>
						<FontAwesomeIcon icon={faMobileAlt} />
						<a href='tel:+4917682014579' title='Mobil'>
							+49 (0)176 82014579
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Emergency;
